<template>
  <v-col
    align-self="start"
    class="bar-shadow primary-background flex-column-with-tabs"
  >
    <v-row class="pa-4 pb-0 flex-grow-0">
      <v-col>
        <DetailsHeader :title="user.name">
          <template #subtitle>
            <p>
              <span class="mr-2">
                <v-icon
                  small
                >
                  mail
                </v-icon>
                {{ user.email }}
              </span>
              <span>
                <v-icon small>
                  mdi-account
                </v-icon>
                {{ user.role }}
              </span>
            </p>
          </template>
        </DetailsHeader>
      </v-col>
    </v-row>

    <v-row class=" flex-grow-1">
      <v-tabs
        slider-color="primary"
        class="tabs-bar-shadow"
      >
        <v-tab>
          Common
        </v-tab>
        <v-tab-item>
          <DateSettings
            class="mb-2"
            :settings="dateFormatSetting"
          />
          <OthersSettings :settings="settings" />
        </v-tab-item>

        <v-tab>
          Jira
        </v-tab>
        <v-tab-item>
          <JiraTab />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-col>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import { DetailsHeader } from '@/components/shared';
import { timePeriod } from '@/constants/timePeriod';
import { LocaleFormat } from '@/helpers/date.helper';

import DateSettings from './DateSettings.vue';
import JiraTab from './JiraTab.vue';
import OthersSettings from './OthersSettings.vue';

export default {
  components: {
    DetailsHeader,
    DateSettings,
    OthersSettings,
    JiraTab,
  },
  data() {
    return {
      settings: [],
    };
  },
  computed: {
    ...mapState('auth/account', ['user']),

    dateFormatSetting() {
      return this.settings.find((el) => el && el.label === 'Date Format Settings');
    },
  },
  async created() {
    this.settings = await this.getSettings();
  },
  methods: {
    ...mapActions('user/settings', ['getSettings']),
    ...mapActions('timesheet/filters', ['setTimeSpanFilter']),

    changeLocaleSettings(value, callback) {
      const period = value === LocaleFormat.EU ? timePeriod.isoWeek : timePeriod.week;

      this.setTimeSpanFilter(period);
      callback(value);
    },
  },
};

</script>
