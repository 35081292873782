<template>
  <v-dialog
    max-width="400px"
    persistent
    :value="opened"
  >
    <v-card>
      <v-card-title
        class="text-h5 lighten-2"
        primary-title
      >
        New Jira connection
        <v-spacer />
        <v-progress-circular
          v-if="isTesting"
          indeterminate
          :size="24"
          color="primary"
        />
        <template v-else-if="isConnectionValid !== null">
          <v-icon
            v-if="isConnectionValid"
            color="success"
          >
            mdi-checkbox-marked
          </v-icon>
          <v-icon
            v-else
            color="red"
          >
            mdi-minus-box
          </v-icon>
        </template>
      </v-card-title>
      <v-card-text class="pb-4 pt-2">
        <v-form
          ref="form"
          v-model="valid"
          class="jira-credentials-form"
        >
          <v-text-field
            ref="serverUriInput"
            v-model="credentials.server"
            class="secondary-background"
            label="Workspace URL"
            placeholder="https://akvelon.atlassian.net/"
            hide-details
            outlined
            dense
            required
            :rules="[(v) => !!v || 'Workspace URL is required']"
          >
            <v-tooltip
              slot="append"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  help_outline
                </v-icon>
              </template>
              <span>ETS supports only cloud Jira services</span>
            </v-tooltip>
          </v-text-field>

          <v-text-field
            ref="connectionNameInput"
            v-model="credentials.connectionName"
            class="secondary-background"
            placeholder="Akvelon"
            maxlength="45"
            label="Connection Name"
            type="text"
            hide-details
            outlined
            dense
            required
            :rules="[(v) => !!v || 'Connection Name is required']"
          />
          <v-text-field
            ref="loginInput"
            v-model="credentials.login"
            class="secondary-background"
            placeholder="john.doe@akvelon.com"
            label="Login"
            type="text"
            autocomplete="new-login"
            hide-details
            outlined
            dense
            required
            :rules="[(v) => !!v || 'Login is required']"
          />
          <v-text-field
            ref="apiTokenInput"
            v-model="credentials.apiToken"
            class="secondary-background"
            label="API token"
            placeholder="<your-api-token>"
            append-icon="mdi-open-in-new"
            type="password"
            autocomplete="new-password"
            hide-details
            outlined
            dense
            required
            :rules="[(v) => !!v || 'API token is required']"
            @click:append="openApiKeysPage"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          :disabled="!isConnectionValid || !valid"
          @click="addCredentials"
        >
          Add
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!valid || isTesting"
          @click="testConnection"
        >
          Test connection
        </v-btn>
        <v-btn
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiService from '@/services/api-client';
import { mapUserJiraActions, mapUserJiraState } from '@/store/user/jira/user-jira.mappers';

/**
 * @typedef {import('@/services/api-client/types/jira.api.types').AddJiraCredentialsParams} AddJiraCredentialsParams
 */

/** @constant {string} Atlassian tokens URL */
const ATLASSIAN_TOKENS_URL = 'https://id.atlassian.com/manage-profile/security/api-tokens';

export default {
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      /** @type {AddJiraCredentialsParams} */
      credentials: {
        server: '',
        connectionName: '',
        login: '',
        apiToken: '',
      },
      isTesting: false,
      /** @type {boolean | null} */
      isConnectionValid: null,
    };
  },
  computed: {
    ...mapUserJiraState(['jiraCredentials']),
  },
  watch: {
    credentials: {
      handler() {
        this.isConnectionValid = null;
      },
      deep: true,
    },
  },
  created() {
    this.getJiraCredentials();
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapUserJiraActions([
      'getJiraCredentials',
    ]),

    async testConnection() {
      const credentials = { ...this.credentials };
      const isCredentialAlreadExists = this.jiraCredentials.find((credential) => {
        const isExistLogin = credential.login === this.credentials.login;
        const isExistServer = credential.server === this.credentials.server;

        return isExistLogin && isExistServer;
      });

      if (isCredentialAlreadExists) {
        this.flashError('This connection is already established');

        return;
      }

      this.isTesting = true;

      try {
        await apiService.jiraApi.testNewConnection(credentials);

        this.isConnectionValid = true;
      } catch {
        this.isConnectionValid = false;
      } finally {
        this.isTesting = false;
      }
    },
    /** @returns {boolean | undefined} */
    validate() {
      const credentialsForm = /** @type {HTMLFormElement | undefined} */(this.$refs.form);

      return credentialsForm && credentialsForm.validate();
    },
    /** @param {boolean} confirmed */
    addCredentials(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) {
        return;
      }

      try {
        this.$emit('add', { ...this.credentials });
      } finally {
        this.closeDialog();
      }
    },
    /** @param {KeyboardEvent} event */
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.addCredentials(true);
      }
    },
    /** @param {KeyboardEvent} event */
    handleKeypress(event) {
      if (event.key === 'Escape' && this.opened) {
        event.preventDefault();
        this.closeDialog();
      }
    },
    openApiKeysPage() {
      window.open(ATLASSIAN_TOKENS_URL, '_blank');
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      const credentialsForm = /** @type {HTMLFormElement | undefined} */(this.$refs.form);

      if (!credentialsForm) throw new Error('Credentials form not found');

      credentialsForm.reset();
    },
  },
};
</script>

<style lang="less">
.jira-credentials-form > :not(:last-child) {
  margin-bottom: 16px;
}
</style>
