<template>
  <v-col lg="3">
    <v-card class="pa-4">
      <v-card-title class="small-title">Others</v-card-title>
      <template v-for="item of settings">
        <v-col :key="item.name">
          <v-switch
            v-if="item.type === 'boolean'"
            v-model="item.value"
            inset
            hide-details
            color="primary"
            :label="item.label"
            @change="item.onChange"
          />
          <v-text-field
            v-else-if="item.type === 'number'"
            v-model="item.value"
            prepend-icon="filter_5"
            outlined
            :label="item.label"
            @change="item.onChange"
          />
          <v-text-field
            v-else-if="item.type === 'string'"
            v-model="item.value"
            prepend-icon="text_fields"
            outlined
            :label="item.label"
            @change="item.onChange"
          />
          <template v-else />
        </v-col>
      </template>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    settings: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>
