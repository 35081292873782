<template>
  <v-col lg="6">
    <v-card>
      <v-row
        :class="{
          'pa-4': true,
          'pb-2': !!currentCredentials,
        }"
        justify="space-between"
      >
        <v-card-title class="small-title mb-0">Connections</v-card-title>
        <v-btn
          color="primary"
          @click="isCreationDialogOpened = true"
        >
          Add connection
        </v-btn>
      </v-row>
      <v-divider />
      <v-row>
        <v-col
          class="pb-4"
          lg="4"
        >
          <v-list class="pa-0">
            <v-list-item-group
              mandatory
              :value="currentCredentialsIndex"
              @change="selectCredentials"
            >
              <v-list-item
                v-for="(credential, index) in jiraCredentials"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-avatar color="primary">
                    <span class="font-weight-bold white--text">{{ credential.login[0].toLocaleUpperCase() }}</span>
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ credential.connection_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ credential.server }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider vertical />
        <v-col
          v-if="!currentCredentials"
          class="pa-6 text-center"
        >
          <p class="grey--text ma-0">You have no connections at the moment</p>
        </v-col>
        <v-col
          v-else
          class="pa-4"
        >
          <Details :details-columns="currentCredentialsDetails" />
          <v-row class="mt-4">
            <v-btn
              color="primary"
              :disabled="isTesting"
              @click="testConnection"
            >
              Test connection
            </v-btn>
            <v-btn
              class="ml-2"
              color="red"
              outlined
              @click="isDeletionDialogOpened = true"
            >
              Delete connection
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <JiraCredentialsDialog
      :opened="isCreationDialogOpened"
      @close="isCreationDialogOpened = false"
      @add="addCredentials"
    />
    <Dialog
      is-persistent
      title="Connection deletion"
      text="Are you sure you want to delete this connection?"
      confirmation-button-text="Delete"
      :dialog="isDeletionDialogOpened"
      @confirmationAction="deleteCredentials"
    />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

import { Details, Dialog } from '@/components/shared';
import { DateHelper } from '@/helpers/date.helper';
import { NotificationHelper } from '@/helpers/notification.helper';
import apiService from '@/services/api-client';
import { mapUserJiraActions, mapUserJiraState } from '@/store/user/jira/user-jira.mappers';

import JiraCredentialsDialog from './JiraCredentialsDialog.vue';

/**
 * @typedef {import('@/components/shared/Details.vue').DetailsEntry} DetailsEntry
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraCredentials} JiraCredentials
 * @typedef {import('@/services/api-client/types/jira.api.types').AddJiraCredentialsParams} AddJiraCredentialsParams
 */

export default {
  components: {
    JiraCredentialsDialog,
    Dialog,
    Details,
  },
  data() {
    return {
      currentCredentialsIndex: 0,
      isCreationDialogOpened: false,
      isDeletionDialogOpened: false,
      isTesting: false,
    };
  },
  computed: {
    ...mapUserJiraState(['jiraCredentials']),
    ...mapGetters('user/settings', ['denseTables', 'dateFormatSettings']),

    /** @returns {JiraCredentials} */
    currentCredentials() {
      return this.jiraCredentials[this.currentCredentialsIndex];
    },

    /** @returns {DetailsEntry[]} */
    currentCredentialsDetails() {
      return [
        {
          title: this.currentCredentials.connection_name,
          details: [
            {
              title: 'Workspace URL',
              value: this.currentCredentials.server,
            },
            {
              title: 'Login',
              value: this.currentCredentials.login,
            },
            {
              title: 'Creation date',
              value: DateHelper.formatDateAccordingSettings(
                this.currentCredentials.created_at,
                this.dateFormatSettings
              ),
            },
          ],
        },
      ];
    },
  },
  created() {
    this.getJiraCredentials();
  },
  methods: {
    ...mapUserJiraActions([
      'getJiraCredentials',
      'addJiraCredentials',
      'deleteJiraCredentials',
    ]),

    /** @param {number} tabNumber */
    selectCredentials(tabNumber) {
      this.currentCredentialsIndex = tabNumber;
    },

    /** @param {AddJiraCredentialsParams} credentials */
    addCredentials(credentials) {
      this.addJiraCredentials({ credentials });
    },
    /** @param {boolean} confirmed */
    async deleteCredentials(confirmed) {
      this.isDeletionDialogOpened = false;

      if (!confirmed) {
        return;
      }

      try {
        const previousCredentialsIndex = this.currentCredentialsIndex - 1;

        await this.deleteJiraCredentials({ credentialsId: this.currentCredentials.id });

        this.currentCredentialsIndex = previousCredentialsIndex < 0 ? 0 : previousCredentialsIndex;
      } catch {
        NotificationHelper.showError('Failed to delete connection');
      }
    },
    async testConnection() {
      const credentialsId = this.currentCredentials.id;

      try {
        this.isTesting = true;

        await apiService.jiraApi.testExistingConnection({ credentialsId });

        NotificationHelper.showSuccess('Connection established successfully');
      } catch {
        NotificationHelper.showError('Connection failed');
      } finally {
        this.isTesting = false;
      }
    },
  },
};
</script>

<style>
</style>
