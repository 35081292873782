import { mapActions, mapState } from 'vuex';

import { pick } from '@/helpers/pick';
/**
 * @template T
 * @typedef {import('@/store/types/mapped-state').MappedState<T>} MappedState<T>
 */
/**
 * @typedef {import('@/services/api-client/types/jira.api.types').AddJiraCredentialsParams} AddJiraCredentialsParams
 * @typedef {import('./user-jira.actions').UserJiraActions} UserJiraActions
 * @typedef {import('./user-jira.state').UserJiraState} UserJiraState
 */

/** @type {UserJiraActions} */
const mappedUserJiraActions = {
  ...mapActions('user/jira', [
    'getJiraCredentials',
    'getJiraProjects',
    'addJiraCredentials',
    'deleteJiraCredentials',
    'importTimeUnits',
    'clearImportedTimeUnits',
  ]),
};

/**
 * @template {keyof typeof mappedUserJiraActions} A
 * @param {A[]} [actions]
 * @returns {Pick<typeof mappedUserJiraActions, A[][number]>}
 */
export const mapUserJiraActions = (actions) => pick(mappedUserJiraActions, actions);

/** @type {MappedState<UserJiraState>} */
const mappedUserJiraState = {
  ...mapState('user/jira', [
    'jiraCredentials',
    'importResult',
    'jiraProjects',
  ]),
};

/**
 * @template {keyof typeof mappedUserJiraState} S
 * @param {S[]} [states]
 * @returns {Pick<typeof mappedUserJiraState, S[][number]>}
 */
export const mapUserJiraState = (states) => pick(mappedUserJiraState, states);
