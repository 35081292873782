<template>
  <v-col
    v-if="settings"
    lg="3"
  >
    <v-card class="pa-4">
      <v-card-title class="small-title">{{ settings.label }}</v-card-title>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="settings.value"
            @change="changeLocaleSettings($event, settings.onChange)"
          >
            <v-radio
              v-for="(locale, index) in settings.locales"
              :key="index"
              color="primary"
              :label="locale.name"
              :value="locale.value"
            />
          </v-radio-group>
        </v-col>
        <Details :details-columns="datePreview" />
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

import { Details } from '@/components/shared';
import { timePeriod } from '@/constants/timePeriod';
import { DateHelper, LocaleFormat } from '@/helpers/date.helper';

export default {
  components: {
    Details,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    datePreview() {
      return [
        {
          title: 'Preview',
          details: [
            {
              title: 'Date format',
              value: DateHelper.formatDateAccordingSettings(moment(), this.settings.value),
            },
            {
              title: 'Time format',
              value: DateHelper.formatTimeAccordingSettings(moment(), this.settings.value),
            },
            {
              title: 'First day of week',
              value: DateHelper.getFirstDayOfWeekAccordingSettings(this.settings.value) ? 'Monday' : 'Sunday',
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions('timesheet/filters', ['setTimeSpanFilter']),

    changeLocaleSettings(value, callback) {
      const period = value === LocaleFormat.EU ? timePeriod.isoWeek : timePeriod.week;

      this.setTimeSpanFilter(period);
      callback(value);
    },
  },
};
</script>

<style>
</style>
